import React from "react";
import { FaArrowDown } from "react-icons/fa";

const Hero = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-light-text dark:text-dark-text mb-4">
          Welcome to LHManager!
        </h1>
        <p className="text-light-text dark:text-dark-text mb-6">
          Discover our app's amazing services and features.
        </p>
        <p className="text-light-text dark:text-dark-text mb-6">
          Site currently in development.
        </p>
        <div className="flex justify-center gap-4">
          <a
            href="#"
            className="bg-dark-background dark:bg-light-background text-dark-text dark:text-light-text font-semibold py-2 px-4 rounded"
          >
            Get started
          </a>
          <a
            href="#"
            className="border border-dark-background dark:border-light-background text-light-text dark:text-dark-text hover:bg-gray-200 hover:dark:bg-dark-secondary font-semibold py-2 px-4 rounded flex items-center"
          >
            Learn more <FaArrowDown className="ml-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
