import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useTheme } from '../ThemeContext';

const Drawer = ({ isOpen }) => {
    const { theme, toggleTheme } = useTheme();
    const logoSrc = theme === "dark" ? "/favicon.svg" : "/favicon.svg";
  
    return (
        <div className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-50 w-4/5 max-w-sm shadow-md`}>
        <div className='h-full transition-colors duration-300 bg-white dark:bg-dark-secondary'>
          <div className="p-5 flex justify-between items-center">
          <div className="text-lg font-semibold text-light-text dark:text-dark-text">
          <img src={logoSrc} alt="Logo" className="h-12" />
        </div>
          </div>
          <div className="mt-10 ml-6 space-y-4">
            <a href="#" className="block text-lg text-light-text dark:text-dark-text hover:text-primary hover:underline">Home</a>
            <a href="#" className="block text-lg text-light-text dark:text-dark-text hover:text-primary hover:underline">About</a>
            <a href="#" className="block text-lg text-light-text dark:text-dark-text hover:text-primary hover:underline">Services</a>
            <a href="#" className="block text-lg text-light-text dark:text-dark-text hover:text-primary hover:underline">Contact</a>
          </div>
          <div className="mt-10 ml-6">
            <button onClick={toggleTheme} className="transition ease-in-out duration-500">
              {theme === 'dark' ? <FaSun className="text-yellow-500 text-2xl" /> : <FaMoon className="text-gray-600 text-2xl" />}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default Drawer;