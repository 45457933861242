import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-light-background dark:bg-dark-background transition-colors duration-300">
      <div className="container mx-auto px-6 md:px-12 py-6">
        <div className="flex justify-center text-light-text dark:text-dark-text">
          © {currentYear} LHManager. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
