import React, { useState } from "react";
import { FaBars, FaSun, FaMoon, FaTimes } from "react-icons/fa";
import Drawer from "./Drawer";
import { useTheme } from "../ThemeContext";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const logoSrc = theme === "dark" ? "/favicon.svg" : "/favicon.svg";

  return (
    <nav className="bg-light-background dark:bg-dark-background shadow py-4 transition-colors duration-300">
      <div className="container mx-auto px-6 md:px-12 flex justify-between items-center">
        <div className="text-lg font-semibold text-light-text dark:text-dark-text">
          <img src={logoSrc} alt="Logo" className="h-12" />
        </div>

        <button
          onClick={handleDrawerToggle}
          className="text-3xl text-light-text dark:text-dark-text md:hidden transition ease-in-out duration-500"
        >
          {isDrawerOpen ? <FaTimes /> : <FaBars />}
        </button>

        <Drawer
          isOpen={isDrawerOpen}
          closeDrawer={() => setIsDrawerOpen(false)}
        />

        <div className="hidden md:flex space-x-4">
          <a
            href="#"
            className=" block text-lg text-light-text dark:text-dark-text hover:underline"
          >
            Home
          </a>
          <a
            href="#"
            className="block text-lg text-light-text dark:text-dark-text hover:underline"
          >
            About
          </a>
          <a
            href="#"
            className="block text-lg text-light-text dark:text-dark-text hover:underline"
          >
            Services
          </a>
          <a
            href="#"
            className="block text-lg text-light-text dark:text-dark-text hover:underline"
          >
            Contact
          </a>
        </div>

        <div className="hidden md:flex items-center">
          <button
            onClick={toggleTheme}
            className="transition ease-in-out duration-500"
          >
            {theme === "dark" ? (
              <FaSun className="text-yellow-500 text-2xl" />
            ) : (
              <FaMoon className="text-gray-600 text-2xl" />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;