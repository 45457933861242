import React from 'react';
import { ThemeProvider } from './ThemeContext';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';

function App() {
  return (
    <ThemeProvider>
      <div className="flex flex-col min-h-screen bg-light-background dark:bg-dark-background transition-colors duration-300">
        <Navbar />
        <div className="flex flex-1 items-center justify-center">
          <Hero />
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;